<script>
	import { t } from '@woltair/translations-package-fe'
	import { BLOG } from '$lib/routes'
	import { Button, Separator } from '$lib/ui-woltair/components'
	import { ArticleBoxLink } from '.'

	export let articles = []
	export let cls
	export let hideTitle = false
	export let hideButton = false
</script>

<div class="magazine-cont {cls}">
	{#if !hideTitle}
		<Separator />
		<h2 class="mb-10 mt-2 text-center !font-extrabold tracking-normal">{t('blog.title')}</h2>
	{/if}
	<div class="box-links gap-8">
		{#each articles as article, i}
			{#if i < 2}
				<ArticleBoxLink {article} />
			{:else}
				<ArticleBoxLink {article} hide={true} />
			{/if}
		{/each}
	</div>
	{#if !hideButton}
		<div class="mt-5 flex justify-center">
			<Button href="/{BLOG}" secondary cls="my-3">{t('blog.loadMore')}</Button>
		</div>
	{/if}
</div>

<style lang="scss">
	.magazine-cont {
		margin: 50px 0;

		h2 {
			text-align: center;
		}
		.box-links {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;

			@media only screen and (max-width: 760px) {
				flex-direction: column;
			}
		}
	}
</style>
